import Empty from "components/Empty";
import HacFlex from "components/HacFlex";
import HacInputText from "components/HacInputText";
import HacText from "components/HacText";
import Loader from "components/Loader";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetDiagnostiqueNoteByEleveQuery } from "redux/features/diagnostiqueApi";

function HistoriqueListeEleves() {
  const location = useLocation();
  // const navigation = useNavigate();

  const [query, setQuery] = useState("");

  const { data: dianostiqueData, isLoading: diagnostiqueLoading } =
    useGetDiagnostiqueNoteByEleveQuery({
      eleveId: parseInt(location?.state?.eleveId),
      diagnosticId: parseInt(location?.state?.diagnosticId),
    });

  // console.log({ dianostiqueData, location });

  return (
    <Template
      topBar={<HeaderDiagnostique title={"Les exercices que l'eleve a fait"} />}
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)", padding: ".5rem" }}
    >
      {/* lalalal */}
      <div className="" style={{ position: "sticky", top: 10 }}>
        <HacInputText
          placeholder={"Rechercher un diagnostique"}
          bg={"#fff"}
          h={20}
          radius={10}
          mb={20}
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
          value={query}
        />
      </div>
      {diagnostiqueLoading ? (
        <Loader type={true} />
      ) : dianostiqueData?.data.length > 0 ? (
        dianostiqueData?.data
          // .filter(
          //   (eleve) =>
          //     eleve?.firstName.toLowerCase().includes(query) ||
          //     eleve?.lastName.toLowerCase().includes(query)
          // )
          .map((item, index) => (
            <div
              className=""
              style={{
                backgroundColor: "#fff",
                width: "100%",
                padding: ".7rem",
                borderRadius: 10,
                marginBottom: 10,
              }}
              key={index}
              onClick={() => {
                // dispatch(setElevePositionnement(item));
                // dispatch(addClasseId(item?.classeId));
                // navigation("/listdiagnostique", { state: { parentId } });
              }}
            >
              <HacFlex gap={15}>
                {/* <div
                  className=""
                  style={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                    width: 50,
                    height: 50,
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "50%",
                  }}
                >
                  <img src="../../../icons/faceBlack.png" alt="" width={25} />
                </div> */}
                <div className="">
                  <HacFlex gap={10}>
                    <HacText weight={600}>Sujet :</HacText>
                    <div
                      className=""
                      dangerouslySetInnerHTML={{ __html: item?.epreuve?.sujet }}
                    ></div>
                  </HacFlex>
                  <HacText mt={10} weight={700} size={15} color={"#003880"}>
                    {item?.note} / {item?.epreuve?.bareme}
                  </HacText>
                  {/* <HacText mt={3} size={12}>
                    Ecole: {item?.school?.name}
                  </HacText> */}
                  {/* <HacFlex gap={10} mt={10}>
                    {item?.niveaux.map((niveau, index) => (
                      <div
                        key={niveau}
                        style={
                          {
                            // backgroundColor: "rgba(0,0,0,0.07)",
                            // padding: "3px 10px",
                            // borderRadius: 20,
                            // border: "1px solid rgba(0,0,0,0.1)",
                          }
                        }
                      >
                        <HacText size={10} color={"gray"}>
                          {niveaux?.data.find((niv) => niv.id === niveau)?.name}{" "}
                          {index + 1 === item?.niveaux.length ? null : "/"}
                        </HacText>
                      </div>
                    ))}
                  </HacFlex> */}
                </div>
              </HacFlex>
            </div>
          ))
      ) : (
        <Empty />
      )}
    </Template>
  );
}

export default HistoriqueListeEleves;
