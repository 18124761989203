import { ApiMananger } from "./apiMananger";

const diagnotiqueApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getDiagnostique: build.query({
      query: ({ classeId, matiereId }) =>
        `/diagnostics/?classeId=${classeId}&matiereId=${matiereId}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueByMatierByClasseByNiveau: build.query({
      query: ({ classeId, niveauId, matiereId }) =>
        `/diagnostics/?matiereId=${matiereId}&classeId=${classeId}&niveauId=${niveauId}`,
      providesTags: ["Diagnostics"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
    getDiagnostiqueByClasse: build.query({
      query: (classeId) => `/diagnostics/?classeId=${classeId}`,
      providesTags: ["Diagnostics"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
    getDiagnostiqueTentativeByCoach: build.query({
      query: (id) => `/diagnostics/eleves/?coachId=${id}`,
      providesTags: ["Diagnostics"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
    getDiagnostiqueTentativeByEleve: build.query({
      query: (id) => `/diagnostics/eleves/?studentId=${id}`,
      providesTags: ["Diagnostics"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
    getDiagnostiqueNoteByEleve: build.query({
      // eleveId=${eleveId}&
      query: ({ eleveId, diagnosticId }) => `notes/?tentative=${diagnosticId}`,
      providesTags: ["Diagnostics"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
    getEpreuvexByDiagnostique: build.query({
      query: (diagnosticId) => `/epreuves/?diagnosticId=${diagnosticId}`,
      providesTags: ["Diagnostics"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
    getEpreuvexByDiagnostiqueWithNiveau: build.query({
      query: (diagnosticId) => `/epreuves/diagnostic/${diagnosticId}/`,
      providesTags: ["Diagnostics"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
    getElevesDiagnostiquer: build.query({
      query: ({ diagnosticId, schoolId }) =>
        `/diagnostics/notes/?diagnosticId=${diagnosticId}&schoolId=${schoolId}`,
      providesTags: ["Diagnostics"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
    getElevesNotesDiagnostiquer: build.query({
      query: (eleveId) => `/notes/?eleveId=${eleveId}`,
      providesTags: ["Diagnostics"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
    addDiagnostique: build.mutation({
      query({ body, token }) {
        return {
          url: "/notes/",
          method: "POST",
          headers: {
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }]
    }),
    addDiagnostiqueEleveTentative: build.mutation({
      query({ body, token }) {
        return {
          url: "/diagnostics/eleves/",
          method: "POST",
          headers: {
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
    updateiagnostiqueEleveTentative: build.mutation({
      query({ body, token }) {
        return {
          url: "/diagnostics/eleves/" + body.id + "/",
          method: "PUT",
          headers: {
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
  }),
});

export const {
  useGetDiagnostiqueQuery,
  useAddDiagnostiqueMutation,
  useGetDiagnostiqueByClasseQuery,
  useGetDiagnostiqueByMatierByClasseByNiveauQuery,
  useGetEpreuvexByDiagnostiqueQuery,
  useGetElevesDiagnostiquerQuery,
  useGetElevesNotesDiagnostiquerQuery,
  useAddDiagnostiqueEleveTentativeMutation,
  useUpdateiagnostiqueEleveTentativeMutation,
  useGetDiagnostiqueTentativeByCoachQuery,
  useGetDiagnostiqueTentativeByEleveQuery,
  useGetDiagnostiqueNoteByEleveQuery,
  useGetEpreuvexByDiagnostiqueWithNiveauQuery,
} = diagnotiqueApi;
