import ForgetPassword from "pages/auth/ForgetPassword";
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import CoachPage from "pages/coach/CoachPage";
import Diagnostique from "pages/diagnostique/Diagnostique";
import ExerciceDiagnostique from "pages/diagnostique/ExerciceDiagnostique";
import FinishDiagnostique from "pages/diagnostique/FinishDiagnostique";
import SelectNiveau from "pages/diagnostique/SelectNiveau";
import SelectSchool from "pages/diagnostique/SelectSchool";
import ParentEleveList from "pages/parent/ParentEleveList";
import ParentList from "pages/parent/ParentList";
import ParentPage from "pages/parent/ParentPage";
import TestDePositionnementElevePageDesign from "pages/testDePositionnement/TestDePositionnementElevePageDesign";
import ListeEleveByCoachClasseSchool from "pages/testDePositionnement/ListeEleveByCoachClasseSchool";
import { Navigate, Outlet } from "react-router-dom";
import ListDiagnostique from "pages/diagnostique/ListDiagnostique";
import Options from "pages/diagnostique/Options";
import HistoriqueListeEleves from "pages/diagnostique/historique/HistoriqueListeEleves";
import HistoriqueListeDiagnostique from "pages/diagnostique/historique/HistoriqueListeDiagnostique";
import EleveWithautSchool from "pages/eleves/EleveWithautSchool";

/**
 * Public routes
 */
export const publicRoutes = [
  {
    path: "*",
    element: <Navigate to={"/login"} />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
  {
    path: "/register/:phone",
    element: <Register />,
  },
];

/**
 * Private routes
 */
export const privateRoutes = [
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
  {
    path: "/",
    element: (
      <>
        <Outlet />
      </>
    ),
    subPath: [
      // Ecole path
      { path: "", element: <Diagnostique /> },
      {
        path: "ecoles",
        element: (
          <>
            <Outlet />
          </>
        ),
        subPath: [
          { path: "", element: <SelectSchool /> },
          {
            path: "options",
            element: (
              <>
                <Outlet />
              </>
            ),
            subPath: [
              {
                path: "",
                element: <Options />,
              },
              {
                path: "eleveWithoutSchool",
                element: <EleveWithautSchool />,
              },
              {
                path: "diagnostique",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                subPath: [
                  { path: "", element: <ListeEleveByCoachClasseSchool /> },
                  {
                    path: "eleve",
                    element: <TestDePositionnementElevePageDesign />,
                  },
                ],
              },
              {
                path: "historiques",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                subPath: [
                  { path: "", element: <HistoriqueListeDiagnostique /> },
                  {
                    path: ":diagnostiqueId",
                    element: <HistoriqueListeEleves />,
                  },
                  {
                    path: "eleve",
                    element: <TestDePositionnementElevePageDesign />,
                  },
                ],
              },
            ],
          },
        ],
      },

      // Parent Path
      {
        path: "parent",
        element: (
          <>
            <Outlet />
          </>
        ),
        subPath: [
          { path: "", element: <ParentPage /> },
          {
            path: "list",
            element: (
              <>
                <Outlet />
              </>
            ),
            subPath: [
              { path: "", element: <ParentList /> },
              {
                path: "eleves/:parentId",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                subPath: [
                  { path: "", element: <ParentEleveList /> },
                  {
                    path: "parentEleve",
                    element: <TestDePositionnementElevePageDesign />,
                  },
                ],
              },
            ],
          },
        ],
      },

      // Coach Path
      {
        path: "coach",
        element: (
          <>
            <Outlet />
          </>
        ),
        subPath: [{ path: "", element: <CoachPage /> }],
      },

      // Diagnostique
      {
        path: "listdiagnostique",
        element: (
          <>
            <Outlet />
          </>
        ),
        subPath: [
          { path: "", element: <ListDiagnostique /> },
          { path: ":id", element: <ExerciceDiagnostique /> },
        ],
      },

      // Niveaux Path
      {
        path: "niveau",
        element: (
          <>
            <Outlet />
          </>
        ),
        subPath: [
          {
            path: ":matiere/:matiereId",
            element: (
              <>
                <Outlet />
              </>
            ),
            subPath: [
              { path: "", element: <SelectNiveau /> },
              {
                path: ":niveauId",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                subPath: [
                  { path: "", element: <ExerciceDiagnostique /> },
                  // {
                  //   path: "listdiagnostique",
                  //   element: <ExerciceDiagnostique />,
                  // },
                  {
                    path: "finish",
                    element: <FinishDiagnostique />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
